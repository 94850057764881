import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import SweetAlert from "react-bootstrap-sweetalert";

import { Loading, ButtonPrint } from "../../../components"
import { dateToLocal, calculaIdade } from '../../../utils/dates';
import { serieLabel, turnoLabel } from '../../../utils/misc';

class PrintAluno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {}
    }
    this.loadFromAPI = props.loadFromAPI;
    this.closePrintAluno = props.closePrintAluno;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      let record = Object.assign({}, props.record);

      for (const key in record) {
        if (key === 'nascimento' || key === 'inscricao' || key === 'matricula' || key === 'formatura' || key === 'desistencia') {
          if (record[key] && record[key].length) record[key] = new Date(record[key].replace('Z', ''));
          else record[key] = '';
        } else if (record[key] === null) {
          record[key] = '';
        }
      }

      console.log('record', record)

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      formValid: false
    }, () => this.closePrintAluno());
  };

  Print = () => {
    const printContents = document.getElementById('printablediv').innerHTML;
    
    // Abra uma nova janela para imprimir o conteúdo
    const printWindow = window.open('', '_blank');
    
    // Adicione o conteúdo ao corpo da nova janela
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link rel="stylesheet" href="path_to_your_css_file.css" />'); // Inclua aqui o caminho para o seu CSS
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
  
    // Fecha o documento da janela para poder imprimir
    printWindow.document.close();
  
    // Espera um pouco para garantir que o conteúdo seja renderizado
    printWindow.focus();
    printWindow.print();
    
    // Fecha a janela após a impressão
    printWindow.close();
  };

  showAlert = (type, message) => {
    this.setState({
      alert: (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{message}</SweetAlert>)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { showModal, isLoading, alert, record } = this.state;

    return (
      <React.Fragment>
        {alert}
        <Modal
          size="lg"
          show={showModal}
          onHide={this.closeModal}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Imprimir <ButtonPrint onClick={() => this.Print()} >Imprimir</ButtonPrint></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>

              <Row id="printablediv">
                <Col sm={12}>
                  <img src="https://fabricadegaiteiros.com.br/images/logo-print.png" width={200} />
                </Col>
                <Col sm={12}>
                  <h2 className="mb-5 mt-3">Ficha de Matrícula</h2>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={2}>
                      <strong>Unidade:</strong> 
                    </Col>
                    <Col sm={10}>
                      {record.unidade}
                    </Col>
                    <Col sm={2}>
                      <strong>Nome do aluno:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.nome}
                    </Col>
                    <Col sm={2}>
                      <strong>Idade:</strong>
                    </Col>
                    <Col sm={10}>
                      {calculaIdade(record.nascimento)} anos
                    </Col>
                    <Col sm={2}>
                      <strong>Data de nascimento:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.nascimento ? dateToLocal(record.nascimento) : '--/--/----'}
                    </Col>
                    <Col sm={2}>
                      <strong>Endereço:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.endereco} - {record.bairro} - {record.municipio}
                    </Col>
                    <Col sm={2}>
                      <strong>CPF:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.cpf}
                    </Col>
                    <Col sm={2}>
                      <strong>RG:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.rg}
                    </Col>
                    <Col sm={2}>
                      <strong>Nome do responsável legal:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.responsavel}
                    </Col>
                    <Col sm={2}>
                      <strong>RG do responsável legal:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.respRG}
                    </Col>
                    <Col sm={12}>
                      &nbsp;
                    </Col>
                    <Col sm={12}>
                      <strong>Telefones de Contato:</strong>
                    </Col>
                    <Col sm={2}>
                      <strong>Responsável:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.respFone}
                    </Col>
                    <Col sm={2}>
                      <strong>Emergência:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.foneEmergencia}
                    </Col>
                    <Col sm={2}>
                      <strong>Whatsapp:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.fone}
                    </Col>
                    <Col sm={12}>
                      &nbsp;
                    </Col>
                    <Col sm={2}>
                      <strong>Nome da Escola:</strong>
                    </Col>
                    <Col sm={10}>
                      {record.colegio}
                    </Col>
                    <Col sm={2}>
                      <strong>Série:</strong>
                    </Col>
                    <Col sm={10}>
                      {serieLabel(record.serie)}
                    </Col>
                    <Col sm={2}>
                      <strong>Turno:</strong>
                    </Col>
                    <Col sm={10}>
                      {turnoLabel(record.turno)}
                    </Col>
                    <Col sm={12}>
                      &nbsp;
                    </Col>
                    <Col sm={12}>
                      <strong>Declaração e Autorização de Uso de Imagem</strong>
                    </Col>
                    <Col sm={12}>
                      Declaro que recebi todas as informações referentes ao funcionamento da Fábrica de Gaiteiros estando de acordo com as mesmas, autorizando, por meio desta, o uso de fotos, filmes ou gravações em que apareçam a imagem de meu filho (a) e minha imagem, para finalidades legítimas.
                    </Col>
                    <Col sm={12}>
                      &nbsp;
                    </Col>
                    <Col sm={12}>
                      <p>{record.municipio}, {dateToLocal(record.inscricao)}</p>
                      <br />
                      <p>______________________________</p>
                      <p>Assinatura do Responsável Legal</p>
                      <p>RG nº {record.respRG}</p>
                    </Col>
                  </Row>              
                </Col>
              </Row>

            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PrintAluno;